<template>
    <div>
        <b-tabs>
            <b-tab title="Entidades">
                <lista-entidades></lista-entidades>
            </b-tab>
            <b-tab title="Estadísticas" lazy>
                <estadisticas-entidades></estadisticas-entidades>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
export default {
    components: {
        ListaEntidades: () =>
            import("@/views/admin/entidades/tabs/ListaEntidades.vue"),
        EstadisticasEntidades: () =>
            import("@/views/admin/entidades/tabs/EstadisticasEntidades.vue"),
    },
};
</script>
